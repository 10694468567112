import * as React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { AuthorsJsonConnection } from '../domain/graphql-types';
import { authorFromAuthorsJsonEdge } from '../domain/converters';


import '../css/about.css';

import SignUpSection from '../components/shared/SignUpSection/SignUpSection';
import { Clear } from '../components/shared/Clear';
import { MainLayout } from '../layouts/MainLayout';
import { Seo } from '../components/shared/Seo/Seo';
import { PageHeadingMain } from '../components/shared/PageHeaders';
import { NSiteCTA } from '../ncomponents/shared/sitecta/sitecta';

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface AboutPageProps {
  data: {
    authorsConnection: AuthorsJsonConnection;
  };
}

export default class extends React.Component<AboutPageProps, {}> {
  constructor(props: AboutPageProps) {
    super(props);
  }

  public componentDidMount() {
    // this.mountWebflowJs();
}

  private mountWebflowJs() {
    const scriptJq = document.createElement("script");
    scriptJq.src = `https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.4.1.min.220afd743d.js`;
    //scriptJq.integrity = 'sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo=';


    const script = document.createElement("script");
    script.src = `js/webflow.js`;




    document.body.appendChild(scriptJq);
    document.body.appendChild(script);
  }

  public render() {
    const authors = this.props.data.authorsConnection.edges.map(
      authorFromAuthorsJsonEdge
    );

    const breadCrumbs = [
      { name: 'All courses', url: '/' },
      { name: 'About', url: '' }
    ];

    const pageTitle = `About | Nuvious`;

    return (
      <MainLayout>
        <Seo path="/about" />
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>

        <div className="section cc-home-wrap">

          <div className="intro-header cc-subpage">
            <div className="intro-content">
              <div className="heading-jumbo">About Us<br /></div>
            </div>
          </div>

          <div className="container">
            <div className="motto-wrap">
              <div className="heading-jumbo-small">We are creative thinkers who have built a business to change the way you get results.</div>
            </div>
            <div className="divider" />
            <div className="about-story-wrap">
              <p className="paragraph-light">When the company started a few years ago in Washington D.C, our core concept helped set us apart and a growing list of customers would experience the “ Nuvious Way “. Our passion and unique style translated to incredible results when providing fantastic custom programs for businesses across America. To us, accountability and education are foundation of our winning formula.<br /><br />Once a client, always a client – We don’t just sell services here, we build relationships. Working alongside our clients doesn’t end when the job is done, we have a responsibility to ensure that each of our clients is well-served even after our work is done. That’s the secret behind the high rate of repeat business with our valued customers.<br /><br />The culture of good business – Our work environment is geared to nurturing the success of each individual employee at Nuvious. We encourage growth and creativity, and we hire selectively to ensure that we don’t just have co-workers, we have a team.<br /><br />Open door policy – whether it’s with our clients or within our own team, communication is success. We offer our customers complete transparency and open communication while working with them on projects. This means our end result respects the ever-changing demands of creating successful programs to integrate into company infrastructures.</p>
            </div>
            <div className="divider" />
          </div>
        </div>

        <NSiteCTA />

      </MainLayout>
    );
  }
}

export const aboutPageQuery = graphql`
  query AboutPageQuery {
    #get authors
    authorsConnection: allAuthorsJson {
      totalCount
      edges {
        node {
          id
          title
          name
          picture
          bio
          biolong
          twitter
          github
        }
      }
    }
  }
`;
